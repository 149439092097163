export const queryKeys = {
  allGeo: "allGeoData",
  selected: "selectedData",
  allMediaType: "allMediaType",
  AllBeat: "All Beats",
  ALLTOPIC: "allTopic",
  ALLBRAND: "allBrand",
  ALLSPOKESPERSON: "allSpokesPerson",
  ALLCOMPETITOR: "allCompetitor",
  DASHBOARD: "dashboard",
  CRM: "crm",
  USERINFO: "userInfo",
  JOURNALISTPROFILE: "journalistProfile",
  BRANDAPI: "brandApi",
  BRANDAPIAGGREGATE: "brandApiAggregation",
  BRANDBEATTRUE: "brandBeatTrue",
  TRENDINGINFLUENCER: "trendingInfluencer",
  TRENDINGSPOKESPERSON: "trendingSpokesperson",
  TRENDINGTOPIC: "trendingTopic",
  TOPICWEBSITE: "topicWebsite",
  TOPICAUTHOR: "topicAuthor",
  journoSuggestions: "journoSuggestions",
  journoPortfolio: "journoPortfolio",
};
