import React, { lazy, Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import App from "./App";

import { DashboardProvider } from "./context/DashboardContext";
import { ReportBrandProvider } from "./context/ReportBrandContext";
import { ReportInfluencerProvider } from "./context/ReportInfluencerContext";
import { ReportSpokespersonProvider } from "./context/ReportSpokespersonContext";
import { ReportTopicsProvider } from "./context/ReportTopicsContext";
import GeoByJourno from "./components/GEO/GeoCity/GeoByJourno";
import ErrorPage from "./components/ErrorPage";
import tokenService from "./Services/token.service";
import SetBrand from "./components/BrandDashboard/BrandKeywords/SetBrand";

const CampaignDetail = lazy(() =>
  import("./components/CampaignManager/CampaignDetail/CampaignDetail")
);
const TemplatePreview = lazy(() =>
  import("./components/CampaignManager/TemplateLayout/TemplatePreview")
);

const MailerTemplate = lazy(() =>
  import("./components/CampaignManager/TemplateLayout/MailerTemplate")
);

const ChooseTemplate = lazy(() =>
  import("./components/CampaignManager/PickTemplate/ChooseTemplate")
);

const CampaignSummary = lazy(() =>
  import("./components/CampaignManager/CampaignSummary/CampaignSummary")
);
const BrandDashboard = lazy(() =>
  import("./components/BrandDashboard/BrandDashboard")
);
const ByShowJournalists = lazy(() =>
  import("./components/MediaTypes/OutletCategory/ByShowJournalists")
);
const JournalistByShow = lazy(() =>
  import("./components/MediaTypes/OutletCategory/JournalistByShow")
);

const JournalistByProducers = lazy(() =>
  import("./components/MediaTypes/OutletCategory/JournalistByProducers")
);
const JournalistByAnchor = lazy(() =>
  import("./components/MediaTypes/OutletCategory/JournalistByAnchor")
);
const Geo = lazy(() => import("./components/GEO/GeoCity/Geo"));

const Instagram = lazy(() => import("./components/Social/Instagram/Instagram"));
const Twitter = lazy(() => import("./components/Social/Twitter/Twitter"));

const SmartProfile = lazy(() =>
  import("./components/SmartProfile/SmartProfile")
);
const Dashboard2 = lazy(() => import("./components/Dashboard2/Dashboard2"));

const BeforeLogin = lazy(() => import("./components/BeforeLogin/BeforeLogin"));
const BeforeForgotPassword = lazy(() =>
  import("./components/ForgotPassword/BeforeForgotPassword")
);
const BeforeResetPassword = lazy(() =>
  import("./components/ForgotPassword/BeforeResetPassword")
);
const AllMedia = lazy(() => import("./components/DataConfig/AllMedia"));
const Esg = lazy(() => import("./components/ESG/Esg"));
const AccountSetting = lazy(() =>
  import("./components/AccountSettings/AccountSetting")
);
const ChangePassword = lazy(() =>
  import("./components/AccountSettings/ChangePassword")
);
const Dashboard = lazy(() => import("./components/Dashboard/Dashboard"));
const JP = lazy(() => import("./components/JournalistProfile/JP"));
const BeatWatch = lazy(() => import("./components/BeatWatch/BeatWatch"));
const UserAuthCheck = lazy(() => import("./components/UserAuth/UserAuthCheck"));
const PersonalInfo = lazy(() =>
  import("./components/AccountSettings/PersonalInfo")
);
const Loader = lazy(() => import("./components/Loader/Loader"));
const AllSettings = lazy(() => import("./components/DataConfig/AllSettings"));
const AllState = lazy(() => import("./components/DataConfig/AllState"));
const AllBeat = lazy(() => import("./components/DataConfig/AllBeat"));
const AllCompetitors = lazy(() =>
  import("./components/DataConfig/AllCompetitors")
);
const AllTopics = lazy(() => import("./components/DataConfig/AllTopics"));
const AllBrands = lazy(() => import("./components/DataConfig/AllBrands"));
const AllSpokesPerson = lazy(() =>
  import("./components/DataConfig/AllSpokesPerson")
);
const TopicsLanding = lazy(() =>
  import("./components/Reports/Topics/TopicsLanding")
);
const TopicsDetail = lazy(() =>
  import("./components/Reports/Topics/TopicsDetail")
);
const FullArticle = lazy(() => import("./components/BeatWatch/FullArticle"));
const ConfigureCRM = lazy(() =>
  import("./components/MyCRM/PreConfigCRM/ConfigureCRM")
);
const BrandsLanding = lazy(() =>
  import("./components/Reports/Brands/BrandsLanding")
);
const InfluencerLanding = lazy(() =>
  import("./components/Reports/Influencer/InfluencerLanding")
);
const SpokespersonLanding = lazy(() =>
  import("./components/Reports/Spokesperson/SpokespersonLanding")
);
const MediaTypeOutlet = lazy(() =>
  import("./components/MediaTypes/MediaTypeOutlet")
);
const JournalistByOutlet = lazy(() =>
  import("./components/MediaTypes/JournalistByOutlet")
);
const ListTracking = lazy(() => import("./components/Campaign/ListTracking"));
const CampaignList = lazy(() => import("./components/Campaign/CampaignList"));
const CampaignManager = lazy(() =>
  import("./components/Campaign/CampaignManager")
);
const JournalistSearch = lazy(() =>
  import("./components/JournalistSearch/JournalistSearch")
);
const JournalistByBeat = lazy(() =>
  import("./components/MediaTypes/JournalistByBeat")
);
const OutletEditor = lazy(() =>
  import("./components/MediaTypes/OutletCategory/OutletEditor")
);
const BureauChief = lazy(() =>
  import("./components/MediaTypes/OutletCategory/BureauChief")
);
const Columnist = lazy(() =>
  import("./components/MediaTypes/OutletCategory/Columnist")
);
const International = lazy(() =>
  import("./components/MediaTypes/OutletCategory/International")
);
const Supplements = lazy(() =>
  import("./components/MediaTypes/OutletCategory/Supplements")
);
const SupplementJournalist = lazy(() =>
  import("./components/MediaTypes/OutletCategory/SupplementJournalist")
);

const SupplementJourno = lazy(() =>
  import("./components/MediaTypes/OutletCategory/SupplementJourno")
);
const BeforeTeaTime = lazy(() =>
  import("./components/BeforeLogin/BeforeTeaTime")
);
const HomePage = lazy(() => import("./components/BeforeLogin/HomePage"));
const TermsCondition = lazy(() =>
  import("./components/BeforeLogin/TermsCondition")
);
const PrivacyPolicy = lazy(() =>
  import("./components/BeforeLogin/PrivacyPolicy")
);
const InstagramDetail = lazy(() =>
  import("./components/Social/Instagram/InstagramDetail")
);
const Podcast = lazy(() => import("./components/Social/Podcast/Podcast"));
const PodcastListing = lazy(() =>
  import("./components/Social/Podcast/PodcastListing")
);
const PodcastDetail = lazy(() =>
  import("./components/Social/Podcast/PodcastDetail")
);
const ResetPassword = lazy(() =>
  import("./components/ForgotPassword/ResetPassword")
);
const Mycrm = lazy(() => import("./components/MyCRM/Mycrm"));
const Skribe365Home = lazy(() =>
  import("./components/Skribe365Home/Skribe365Home")
);
const TopicReport = lazy(() =>
  import("./components/Reports/Topics/TopicsDetail")
);
const MediaTypes = lazy(() => import("./components/MediaTypes/MediaTypes"));
const BuildCampaign = lazy(() => import("./components/Campaign/BuildCampaign"));

const Favorites = lazy(() =>
  import("./components/Dashboard2/FavoritesHomePage")
);

const AppRoute = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<Loader />}>
        <HomePage />
      </Suspense>
    ),
    errorElement: (
      <UserAuthCheck path={"errroPage"}>
        <ErrorPage />
      </UserAuthCheck>
    ),
  },
  {
    path: "/tea-time",
    element: (
      <Suspense fallback={<Loader />}>
        <BeforeTeaTime />
      </Suspense>
    ),
  },
  {
    path: "/login",
    element: (
      <Suspense fallback={<Loader />}>
        <BeforeLogin />
      </Suspense>
    ),
  },
  {
    path: "/forgot-password",
    element: (
      <Suspense fallback={<Loader />}>
        <BeforeForgotPassword />
      </Suspense>
    ),
  },
  {
    path: "/reset-password",
    element: (
      <Suspense fallback={<Loader />}>
        <ResetPassword />
      </Suspense>
    ),
  },
  {
    path: "/terms-condition",
    element: (
      <Suspense fallback={<Loader />}>
        <TermsCondition />
      </Suspense>
    ),
  },
  {
    path: "/privacy-policy",
    element: (
      <Suspense fallback={<Loader />}>
        <PrivacyPolicy />
      </Suspense>
    ),
  },
  {
    path: "/reset-password",
    element: (
      <Suspense fallback={<Loader />}>
        <BeforeResetPassword />
      </Suspense>
    ),
  },
  {
    path: "/",
    element: (
      <Suspense fallback={<Loader />}>
        <App />
      </Suspense>
    ),

    children: [
      {
        path: "/beat-watch",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"beatWatch"}>
              <BeatWatch />
            </UserAuthCheck>
          </Suspense>
        ),
      },

      {
        path: "dashboard",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"dashboard"}>
              <Dashboard2 />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "skribe365",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"skribe365"}>
              <Skribe365Home />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "favorites",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"favorites"}>
              <Favorites />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "/",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"homePage"}>
              <AllSettings />
            </UserAuthCheck>
          </Suspense>
        ),
        children: [
          {
            path: "Geo Location",
            element: (
              <UserAuthCheck path={"geoLocation"}>
                <Suspense fallback={<Loader />}>
                  <AllState />
                </Suspense>
              </UserAuthCheck>
            ),
          },
          {
            path: "Media Types",
            element: (
              <UserAuthCheck path={"allMediaTypes"}>
                <Suspense fallback={<Loader />}>
                  <AllMedia />
                </Suspense>
              </UserAuthCheck>
            ),
          },
          {
            path: "Beats",
            element: (
              <UserAuthCheck path={"Beats"}>
                <Suspense fallback={<Loader />}>
                  <AllBeat />
                </Suspense>
              </UserAuthCheck>
            ),
          },
          {
            path: "Competitors",
            element: (
              <UserAuthCheck path={"Competitors"}>
                <Suspense fallback={<Loader />}>
                  <AllCompetitors />
                </Suspense>
              </UserAuthCheck>
            ),
          },
          {
            path: "topics",
            element: (
              <UserAuthCheck path={"topics"}>
                <Suspense fallback={<Loader />}>
                  <AllTopics />
                </Suspense>
              </UserAuthCheck>
            ),
          },
          {
            path: "Brand",
            element: (
              <UserAuthCheck path={"Brand"}>
                <Suspense fallback={<Loader />}>
                  <AllBrands />
                </Suspense>
              </UserAuthCheck>
            ),
          },
          {
            path: "Spokes Person",
            element: (
              <UserAuthCheck path={"spokesPerson"}>
                <Suspense fallback={<Loader />}>
                  <AllSpokesPerson />
                </Suspense>
              </UserAuthCheck>
            ),
          },
        ],
      },
      {
        path: "esg",
        element: (
          <UserAuthCheck path={"esg"}>
            <Suspense fallback={<Loader />}>
              <Esg />
            </Suspense>
          </UserAuthCheck>
        ),
      },
      {
        path: "/",
        element: (
          <UserAuthCheck path={"landingPage"}>
            <Suspense fallback={<Loader />}>
              <AccountSetting />
            </Suspense>
          </UserAuthCheck>
        ),
        children: [
          {
            path: "accountSettings",
            element: (
              <UserAuthCheck path={"accountSettings"}>
                <Suspense fallback={<Loader />}>
                  <PersonalInfo />
                </Suspense>
              </UserAuthCheck>
            ),
          },
          {
            path: "changePassword",
            element: (
              <UserAuthCheck path={"changePassword"}>
                <Suspense fallback={<Loader />}>
                  <ChangePassword />
                </Suspense>
              </UserAuthCheck>
            ),
          },
        ],
      },
      {
        path: "customcrm",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"customcrm"}>
              <ConfigureCRM />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "mycrm",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"mycrm"}>
              <Mycrm />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "brandreport",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"brandreport"}>
              <ReportBrandProvider>
                <BrandsLanding />
              </ReportBrandProvider>
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "influencer",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"influencer"}>
              <ReportInfluencerProvider>
                <InfluencerLanding />
              </ReportInfluencerProvider>
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "spokesperson",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"spokesperson"}>
              <ReportSpokespersonProvider>
                <SpokespersonLanding />
              </ReportSpokespersonProvider>
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "topicsreport",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"topicsreport"}>
              <ReportTopicsProvider>
                <TopicsLanding />
              </ReportTopicsProvider>
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "topicsdetail",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"topicsdetail"}>
              <ReportTopicsProvider>
                <TopicsDetail />
              </ReportTopicsProvider>
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "journalistProfile/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"journalistProfiledetail"}>
              <SmartProfile />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "beat-watch/:id/:name",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"beatWatchIdName"}>
              <FullArticle />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "dashboard/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"dashboardId"}>
              <FullArticle />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "brandreport/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"brandreportId"}>
              <FullArticle />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "influencer/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"influencerId"}>
              <FullArticle />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "spokesperson/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"spokespersonId"}>
              <FullArticle />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "journalist-search",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"journalistSearch"}>
              <JournalistSearch />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "media-types",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"mediaTypes"}>
              <MediaTypes />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "brandDashboard",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"brandDashboard"}>
              <BrandDashboard />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "mediaTypeOutlet/:outletId/:name",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"mediaTypeOutletIdName"}>
              <MediaTypeOutlet />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "journalistByBeat/:selected",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"journalistByBeatSelected"}>
              <JournalistByBeat />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "geo/:cityId",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"geoCityId"}>
              <Geo />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "geobyJourno",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"geobyJourno"}>
              <GeoByJourno />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "campaign-manager",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"campaignManager"}>
              <CampaignManager />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "campaign-list/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"campaignListId"}>
              <CampaignList />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "list-Tracking/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"listTrackingId"}>
              <ListTracking />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "campaign-action",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"campaignAction"}>
              <BuildCampaign />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "journalistByOutlet/:outletId/:id/:name",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"journalistByOutletIdName"}>
              <JournalistByOutlet />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "journalistByAnchor/:outletId/:id/:name",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"journalistByAnchorIdName"}>
              <JournalistByAnchor />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "journalistByProducers/:outletId/:id/:name",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"journalistByProducersIdName"}>
              <JournalistByProducers />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "journalistByShows/:outletId/:id/:name",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"journalistByShowsIdName"}>
              <JournalistByShow />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "byShowJournalists/:showId/:outletName/:showName/:outletId",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"byShowJournalistsShowNameOutletId"}>
              <ByShowJournalists />
            </UserAuthCheck>
          </Suspense>
        ),
      },

      {
        path: "OutletEditor/:outletId/:id/:name",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"OutletEditorOutletIdName"}>
              <OutletEditor />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "BureauChief/:outletId/:id/:name",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"BureauChiefOutletIdName"}>
              <BureauChief />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "Columnist/:outletId/:id/:name",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"ColumnistOutletIdName"}>
              <Columnist />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "Supplements/:outletId/:id/:name",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"SupplementsOutletIdName"}>
              <Supplements />
            </UserAuthCheck>
          </Suspense>
        ),
      },

      {
        path: "SupplementsJourno/:suppId/:outletId/:id/:name/:supplementName",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"SupplementsJournoSupplementName"}>
              <SupplementJournalist />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "supplementJourno/:supplementId/:outletId/:name",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"supplementJournoOutletIdName"}>
              <SupplementJourno />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "International/:outletId/:id/:name",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"InternationalOutletIdName"}>
              <International />
            </UserAuthCheck>
          </Suspense>
        ),
      },

      {
        path: "X",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"X"}>
              <Twitter />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "instagram",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"instagram"}>
              <Instagram />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "insta-detail/:insta",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"instaDetailInsta"}>
              <InstagramDetail />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "podcast",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"podcast"}>
              <Podcast />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "podcast-listing/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"podcastListingId"}>
              <PodcastListing />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "podcast-detail/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"podcastDetailId"}>
              <PodcastDetail />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "campaign-summary",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"campaginSummary"}>
              <CampaignSummary />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "choose-template/:campaignId",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"chooseTemplate"}>
              <ChooseTemplate />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "mailer-template/:campaignId/:mailerType",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"introTemplate"}>
              <MailerTemplate />
            </UserAuthCheck>
          </Suspense>
        ),
      },

      {
        path: "mailer-template/:campaignId/:mailerType/:update",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"introTemplate"}>
              <MailerTemplate />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "template-preview/:campaignId/:mailerType",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"templatePreview"}>
              <TemplatePreview />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "campaign-detail",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck path={"campaignDetail"}>
              <CampaignDetail />
            </UserAuthCheck>
          </Suspense>
        ),
      },
    ],
  },
]);

export default AppRoute;
