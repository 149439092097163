import React, { useRef, useState, useEffect } from "react";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import BeatPopup from "../AllBeats/BeatPopup";
import { GEOBYCITY } from "../../constants";

import MainSearchContext, {
  useSearchContext,
} from "../../context/MainSearchContext";
import logo from "../assets/skribe-logo.png";
import GetTrackingInfo from "../Filters/GetTrackingInfo";
import userService from "../../Services/user.service";

const Searchbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [mediaDropDown, setMediaDropDown] = useState(false);
  const [mediaDdValue, setMediaDdValue] = useState("Select Type");
  const [journalistSearch, setJournalistSearch] = useState("");
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const previousPath = useRef(location.pathname);
  const testRef = useRef();
  const mediaRef = useRef();
  const geoSuggest = useRef();

  //[DROP DOWN CITY SUGGESTION DATA STORE START]
  const [loading, setLoading] = useState(false);
  const [showSuggetion, setShowSuggetion] = useState(false);
  const [citySuggestion, setCitySuggestion] = useState([]);
  const navigate = useNavigate();
  //[DROP DOWN CITY SUGGESTION DATA STORE END]

  //FOLLOWING CODE FOR TRACKING
  const locate = useLocation();
  const userId = JSON.parse(localStorage.getItem("userInfo"));
  let pathvalue = locate.pathname.split("/").filter((part) => part !== "");
  let urlRead = pathvalue[0];

  const outSide = (e) => {
    if (mediaRef.current && !mediaRef.current.contains(e.target)) {
      setMediaDropDown(false);
    }
    if (testRef.current && testRef.current.contains(e.target)) {
      setMediaDropDown(true);
    }

    if (geoSuggest.current && !geoSuggest.current.contains(e.target)) {
      setShowSuggetion(false);
    }
  };

  const getMediaValue = (eVal) => {
    setSearchVal("");
    setMediaDdValue(eVal);
    setMediaDropDown(false);
  };

  const { setSearchVal } = useSearchContext();
  const { id, outletId, name, cityId } = useParams();
  const storeValue = (e) => {
    const { value } = e.target;
    if (
      currentPath === `/mediaTypeOutlet/${id}/${name}` ||
      currentPath === "/media-types"
    ) {
      setJournalistSearch(value);
    } else if (value === "") {
      setJournalistSearch("");
      setSearchVal("");
    } else {
      setJournalistSearch(value);
      setSearchVal("");
    }
  };

  //[DROP DOWN GEO BY CITY CODE START]
  const byCityUrl = `${GEOBYCITY}?type=GEO`;
  const byOutletUrl = `${GEOBYCITY}?type=Outlet`;
  const geoCity = async (commonUrl) => {
    setLoading(true);
    setCitySuggestion([]);
    setShowSuggetion(true);
    try {
      const url1 = `${commonUrl}&searchText=${journalistSearch}`;
      const [response1] = await Promise.all([userService.get(url1)]);
      if (response1?.response?.status === "Ok") {
        setCitySuggestion(response1.data);
      }
    } catch (error) {
      console.log(error, "check error");
    } finally {
      setLoading(false);
    }
  };

  //[USEEFFECT FOR GEO BY CITY DROP DOWN START]
  useEffect(() => {
    setShowSuggetion(false);
  }, [journalistSearch.length === 0]);
  useEffect(() => {
    if (mediaDdValue === "GEO" && journalistSearch?.length >= 2) {
      let timer = setTimeout(() => {
        geoCity(byCityUrl);
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
    if (mediaDdValue === "Outlet" && journalistSearch?.length >= 2) {
      let timer = setTimeout(() => {
        geoCity(byOutletUrl);
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [journalistSearch?.length]);
  //[/USEEFFECT FOR GEO BY CITY DROP DOWN END]

  //[/DROP DOWN GEO BY CITY CODE END]
  const searchFun = () => {
    setSearchVal(journalistSearch);

    if (journalistSearch && currentPath === "/journalist-search") {
      GetTrackingInfo(
        "journalistHeader",
        journalistSearch,
        urlRead,
        userId.id,
        ""
      );
    }
  };

  useEffect(() => {
    if (previousPath.current !== location.pathname) {
      setCurrentPath(location.pathname);
      previousPath.current = location.pathname;
    }
  }, [location]);

  useEffect(() => {
    window.addEventListener("click", outSide);
    return () => {
      window.removeEventListener("click", outSide);
    };
  }, []);
  // console.log(citySuggestion, "check city suggetion");
  const changeUrl = (val, id, mediaType) => {
    setShowSuggetion(false);
    setJournalistSearch("");
    if (mediaType) {
      navigate(`/journalistByOutlet/${mediaType}/${id}/${val}`);
    } else {
      navigate(`geo/${id}`);
    }
  };
  return (
    <>
      <div className="flex items-center">
        <MainSearchContext>
          <div className="hidden pr-2 md:pr-10 lg:flex">
            <Link to="/dashboard">
              <img src={logo} alt="image" width="80" />
            </Link>
          </div>

          <div className="relative flex flex-col md:justify-center justify-between w-full md:w-auto md:gap-y-0 gap-y-2 pl-0 md:rounded-[10px] md:bg-white md:flex-row">
            <div className="flex justify-between bg-white rounded-[10px]">
              <Link
                to="#"
                ref={testRef}
                onClick={() => setMediaDropDown(!mediaDropDown)}
                className="flex w-full lg:w-[135px] cursor-pointer items-center gap-x-1 px-2 py-2 text-sm text-gray-400 "
              >
                {mediaDdValue}
                <span className="ml-auto text-gray-400 material-icons-outlined">
                  arrow_drop_down
                </span>
              </Link>
            </div>

            <div className="md:border-gray-400 md:border-l md:items-center md:flex md:relative">
              <div className="flex justify-between bg-white rounded-[10px]">
                <div className="flex items-center">
                  <span className="pl-2 text-sm text-gray-400 material-icons-outlined">
                    search
                  </span>
                  <input
                    type="search"
                    className="p-2 px-3 text-sm text-gray-600 lg:w-52 focus:outline-none"
                    placeholder="Search"
                    // disabled={
                    //   currentPath === "/media-types" ||
                    //   currentPath === "/dashboard" ||
                    //   currentPath === "/skribe365" ||
                    //   currentPath === "/favorites" ||
                    //   currentPath === `/mediaTypeOutlet/${id}/${name}` ||
                    //   currentPath === `/journalistProfile/${id}` ||
                    //   (currentPath ===
                    //     `/journalistByOutlet/${outletId}/${id}/${encodeURIComponent(
                    //       name
                    //     )}` &&
                    //     true)
                    // }
                    value={journalistSearch}
                    onChange={(e) => storeValue(e)}
                  />
                  {showSuggetion && (
                    <div
                      ref={geoSuggest}
                      className="w-full flex flex-col absolute left-0 top-[41px] shadow-xl bg-white max-h-36 overflow-scroll overflow-x-hidden rounded-bl-lg rounded-rb-lg rounded-tr-lg"
                    >
                      <ul className="w-full">
                        {citySuggestion ? (
                          citySuggestion?.length > 0 ? (
                            citySuggestion?.map((curItem) => {
                              return (
                                <li>
                                  <span
                                    onClick={() =>
                                      changeUrl(
                                        curItem.value,
                                        curItem.id,
                                        curItem.meditId
                                      )
                                    }
                                    className="cursor-pointer flex items-center p-1 text-sm
                                    text-gray-500 gap-x-2 hover:bg-gray-200
                                    hover:text-gray-700"
                                  >
                                    {!!mediaDdValue &&
                                    mediaDdValue !== "Outlet" ? (
                                      <span className="pl-1 text-lg text-gray-400 material-icons-outlined">
                                        location_city
                                      </span>
                                    ) : (
                                      <span className="pl-1 text-lg text-gray-400 material-icons-outlined">
                                        newspaper
                                      </span>
                                    )}
                                    <span className="flex-1">
                                      {curItem.value.slice(0, 1).toUpperCase()}
                                      {curItem.value.slice(1).toLowerCase()}
                                    </span>
                                  </span>
                                </li>
                              );
                            })
                          ) : loading ? (
                            <li className="flex p-1 text-sm text-gray-500">
                              Loading...
                            </li>
                          ) : (
                            <li className="flex p-1 text-sm text-gray-500">
                              No Record Found
                            </li>
                          )
                        ) : (
                          <li className="flex p-1 text-sm text-gray-500">
                            No Record Found
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>

                <input
                  type="button"
                  value="Search"
                  className=" cursor-pointer bg-[#FF3EA5] px-3 py-2 font-medium text-white rounded-[7px] rounded-l-[0px] focus:outline-none"
                  onClick={() => searchFun()}
                />
              </div>
            </div>
            {mediaDropDown && (
              <div
                ref={mediaRef}
                className="absolute left-0 top-[41px] z-[200] flex  w-[160px]  rounded-lg bg-gray-100 text-sm shadow-md"
              >
                <ul className="flex flex-col flex-grow">
                  <li>
                    <Link
                      to="media-types"
                      name="MediaTypes"
                      className="flex p-2 rounded-lg hover:bg-gray-200"
                      onClick={(e) => getMediaValue(e.target.name)}
                    >
                      Media Types
                    </Link>
                  </li>

                  <li>
                    <Link
                      className="flex p-2 hover:bg-gray-200"
                      to="journalist-search"
                      name="All Journalists"
                      onClick={(e) => getMediaValue(e.target.name)}
                    >
                      Journalists
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="flex p-2 hover:rounded-lg hover:bg-gray-200"
                      name="Outlet"
                      onClick={(e) => {
                        getMediaValue(e.target.name);
                      }}
                    >
                      Outlet
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="flex p-2 hover:rounded-lg hover:bg-gray-200"
                      name="Beats"
                      onClick={(e) => {
                        setIsOpen(true);
                        getMediaValue(e.target.name);
                        document.body.classList.add("overflow-hidden");
                      }}
                    >
                      Beats
                    </Link>
                  </li>
                  <li>
                    <Link
                      name="GEO"
                      className="flex p-2 rounded-lg hover:bg-gray-200"
                      onClick={(e) => getMediaValue(e.target.name)}
                    >
                      GEO
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </MainSearchContext>
        <BeatPopup
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
            document.body.classList.remove("overflow-hidden");
          }}
        />
      </div>
    </>
  );
};

export default Searchbar;
