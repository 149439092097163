import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const RouteTracker = () => {
  const location = useLocation();

  useEffect(() => {

    const previousRoute = localStorage.getItem('currentRoute');

    if (previousRoute) {
      localStorage.setItem('previousRoute', previousRoute);
    }

    localStorage.setItem('currentRoute', location.pathname);
  }, [location]);

  return null;
};

export default RouteTracker;
