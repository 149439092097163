import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MenuData from "./MenuData";
import logo from "../assets/skribe-logo.png";
import { useDispatch, useSelector } from "react-redux";
import { sidebarMenu } from "../../Redux/Action/Settings";
import { mobileMenu } from "../../Redux/Action/Settings";
import { USERINFO } from "../../constants";
import userService from "../../Services/user.service";

const Sidebar = () => {
  //const [AllInfo, setAllInfo] = useState();
  // const userInfo = async () => {
  //   try {
  //     userService.get(`${USERINFO}`).then(function (output) {
  //       let result = output?.data;
  //       setAllInfo(result);
  //       localStorage.setItem("userInfo", JSON.stringify(result));
  //     });
  //   } catch (error) {
  //     alert(error);
  //   }
  // };
  // useEffect(() => {
  //   userInfo();
  // }, []);

  const [open, setOpen] = useState({
    first: true,
    second: false,
    third: false,
    fourth: false,
  });
  const [ismenu, setIsMenu] = useState(true);
  const dispatch = useDispatch();
  const { sideMenu } = useSelector((state) => state.settings);
  const { mobileLeftMenu } = useSelector((state) => state.settings);

  const sidebarData = ["Competition", "Industry", "ESG", "Beatwatch", "GEO"];
  const reports = ["Brands", "Topics", "Influencer", "SpokesPerson"];

  return (
    <>
      {mobileLeftMenu && (
        <div className="absolute inset-0 z-[2] bg-gray-600 opacity-60"></div>
      )}
      <nav
        aria-labelledby="secondory-Navigation"
        className={
          !sideMenu
            ? "fixed -top-2 left-0  -z-0 hidden  h-full w-16 bg-[#8362B1] px-4 pt-6 transition-all ease-out lg:block"
            : mobileLeftMenu
            ? "fixed left-0  top-0 z-[3] h-full w-60 bg-[#8362B1] px-4 pt-6 transition-all ease-in lg:block"
            : "fixed left-0 top-0  hidden h-full w-60 bg-[#8362B1] px-4 pt-6 transition-all ease-in lg:block"
        }
      >
        <div
          className={
            sideMenu
              ? "w-100 flex items-center justify-between text-white"
              : "w-100 mb-36 flex items-center justify-center text-white"
          }
        >
          {sideMenu ? (
            <>
              <img src={logo} alt="" />
              {mobileLeftMenu ? (
                <span
                  onClick={() => dispatch(mobileMenu())}
                  className="material-icons-outlined close-icon-size text-white"
                >
                  close
                </span>
              ) : (
                <Link onClick={() => dispatch(sidebarMenu())}>
                  <span className="material-icons-outlined menu-font p-1 text-white">
                    menu_open
                  </span>
                </Link>
              )}
            </>
          ) : (
            <>
              <Link onClick={() => dispatch(sidebarMenu())}>
                <span className="material-icons-outlined menu-font rounded-md border  border-white p-1 text-white">
                  menu
                </span>
              </Link>
            </>
          )}
        </div>
        {/* {sideMenu && (
          <>
            <Link to="#">
              <div className="my-10 grid w-full grid-flow-col gap-x-4 rounded-md border border-gray-500 p-3 ">
                <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-200 text-center text-lg uppercase text-gray-700">
                  {AllInfo?.userName && AllInfo?.userName.charAt(0)}
                </div>
                <div className="w-4/5 overflow-hidden">
                  <h3 className="overflow-hidden text-ellipsis whitespace-nowrap text-sm font-normal text-white">
                    {AllInfo?.userName && AllInfo?.userName}
                    <span className="material-icons-outlined absolute right-6 text-white">
                      arrow_drop_down
                    </span>
                  </h3>
                  <div className="overflow-hidden text-ellipsis whitespace-nowrap  text-xs font-light text-gray-300">
                    {AllInfo?.email && AllInfo?.email}
                  </div>
                </div>
              </div>
            </Link>
          </>
        )} */}
        {/* {sideMenu && (
          <div className="tex-md mt-32 flex flex-col text-center text-white">
            <span>Pages Coming Soon</span>
          </div>
        )} */}
        {/* <div>
          <ul className="flex flex-col gap-y-7 p-3">
            <li className="flex flex-col ">
              <div
                className={
                  sideMenu ? "flex gap-x-4" : "flex gap-x-4 self-center"
                }
              >
                <span className="flex h-8 w-8 items-center justify-center rounded-full bg-[#53AADA] p-2 text-white">
                  B
                </span>
                {sideMenu && (
                  <>
                    <Link
                      onClick={() => {
                        setOpen({
                          first: !open.first,
                          second: false,
                          third: false,
                        });
                      }}
                      className="flex flex-1 items-center gap-x-4"
                    >
                      <span className="text-white">Brand</span>
                      <span
                        className={
                          open.first
                            ? "material-icons-outlined ml-auto rotate-180 text-white"
                            : "material-icons-outlined ml-auto text-white"
                        }
                      >
                        arrow_drop_down
                      </span>
                    </Link>
                  </>
                )}
              </div>

              {sideMenu && open.first && (
                <ul
                  className={
                    open &&
                    "ml-4 mt-5 flex flex-col gap-y-3 border-l border-[#53AADA] pl-8 text-sm text-gray-400"
                  }
                >
                  {sidebarData.map((curItem, id) => (
                    <MenuData key={id} id={id} description={curItem} />
                  ))}
                </ul>
              )}
            </li>

            <li className="flex flex-col ">
              <div
                className={
                  sideMenu ? "flex gap-x-4" : "flex gap-x-4 self-center"
                }
              >
                <span className="flex h-8 w-8 items-center justify-center rounded-full bg-[#53AADA] p-2 text-white">
                  I
                </span>
                {sideMenu && (
                  <>
                    <Link
                      onClick={() => {
                        setOpen({
                          second: !open.second,
                          first: false,
                          third: false,
                        });
                      }}
                      className="flex flex-1 items-center gap-x-4"
                    >
                      <span className="text-white">Insights</span>
                      <span
                        className={
                          open.second
                            ? "material-icons-outlined ml-auto rotate-180 text-white"
                            : "material-icons-outlined ml-auto text-white"
                        }
                      >
                        arrow_drop_down
                      </span>
                    </Link>
                  </>
                )}
              </div>

              {sideMenu && open.second && (
                <ul
                  className={
                    open &&
                    "ml-4 mt-5 flex flex-col gap-y-3 border-l border-[#53AADA] pl-8 text-sm text-gray-400"
                  }
                >
                  {sidebarData.map((curItem, id) => (
                    <MenuData key={id} id={id} description={curItem} />
                  ))}
                </ul>
              )}
            </li>

            <li className="flex flex-col ">
              <div
                className={
                  sideMenu ? "flex gap-x-4" : "flex gap-x-4 self-center"
                }
              >
                <span className="flex h-8 w-8 items-center justify-center rounded-full bg-[#53AADA] p-2 text-white">
                  A
                </span>
                {sideMenu && (
                  <>
                    <Link
                      onClick={() => {
                        setOpen({
                          third: !open.third,
                          first: false,
                          second: false,
                        });
                      }}
                      className="flex flex-1 items-center gap-x-4"
                    >
                      <span className="text-white">Analytics</span>
                      <span
                        className={
                          open.third
                            ? "material-icons-outlined ml-auto rotate-180 text-white"
                            : "material-icons-outlined ml-auto text-white"
                        }
                      >
                        arrow_drop_down
                      </span>
                    </Link>
                  </>
                )}
              </div>

              {sideMenu && open.third && (
                <ul
                  className={
                    open &&
                    "ml-4 mt-5 flex flex-col gap-y-3 border-l border-[#53AADA] pl-8 text-sm text-gray-400"
                  }
                >
                  {sidebarData.map((curItem, id) => (
                    <MenuData key={id} id={id} description={curItem} />
                  ))}
                </ul>
              )}
            </li>

            <li className="flex flex-col ">
              <div
                className={
                  sideMenu ? "flex gap-x-4" : "flex gap-x-4 self-center"
                }
              >
                <span className="flex h-8 w-8 items-center justify-center rounded-full bg-[#53AADA] p-2 text-white">
                  R
                </span>
                {sideMenu && (
                  <>
                    <Link
                      onClick={() => {
                        setOpen({
                          fourth: !open.fourth,
                          third: false,
                          first: false,
                          second: false,
                        });
                      }}
                      className="flex flex-1 items-center gap-x-4"
                    >
                      <span className="text-white">Reports</span>
                      <span
                        className={
                          open.fourth
                            ? "material-icons-outlined ml-auto rotate-180 text-white"
                            : "material-icons-outlined ml-auto text-white"
                        }
                      >
                        arrow_drop_down
                      </span>
                    </Link>
                  </>
                )}
              </div>

              {sideMenu && open.fourth && (
                <ul
                  className={
                    open &&
                    "ml-4 mt-5 flex flex-col gap-y-3 border-l border-[#53AADA] pl-8 text-sm text-gray-400"
                  }
                >
                  {reports.map((curItem, id) => (
                    <MenuData key={id} id={id} description={curItem} />
                  ))}
                </ul>
              )}
            </li>
          </ul>
        </div> */}
      </nav>
    </>
  );
};

export default Sidebar;
