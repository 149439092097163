import React, { useEffect, useRef, useState } from "react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

const TawkMessenger = () => {
  const [AllInfo, setAllInfo] = useState({}); // Initialize with an empty object
  const tawkMessengerRef = useRef();
  const [isTawkLoaded, setIsTawkLoaded] = useState(false);
  const getInfo = JSON.parse(localStorage.getItem("userInfo"));

  // const userInfo = async () => {
  //   try {
  //     const output = await userService.get(USERINFO);
  //     const result = output?.data;

  //     setAllInfo(result);
  //   } catch (error) {
  //     console.error("Error fetching user info:", error);
  //   }
  // };
  // useEffect(() => {
  //   userInfo();
  // }, []);

  useEffect(() => {
    if (tawkMessengerRef.current == null || !isTawkLoaded) return;
    if (getInfo?.name && getInfo?.email) {
      tawkMessengerRef.current.setAttributes(
        {
          name: getInfo.name,
          email: getInfo.email,
          phone: getInfo.phoneNumber,
          hash: "hash-value",
        },
        function (error) {
          if (error) {
            console.error("Error setting Tawk attributes:", error);
          }
        }
      );
    } else {
      console.warn("AllInfo is missing name or email:", AllInfo);
    }
  }, [getInfo, isTawkLoaded]);
  return (
    <TawkMessengerReact
      onLoad={() => {
        setIsTawkLoaded(true);
      }}
      propertyId="66502ec29a809f19fb345a68"
      widgetId="1hukjdabn"
      ref={tawkMessengerRef}
    />
  );
};
export default TawkMessenger;
