import React from "react";
// import 404error from "../assets/404error.jpg"
// import noUser from "../assets/noUser.png";
import { Link, useNavigate, useRouteError } from "react-router-dom";
import Error from "../components/assets/Error.jpg";

const ErrorPage = () => {
  const error = useRouteError();
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  if (error.status === 404) {
    return (
      <div className="text-center ">
        <span onClick={goBack} className="cursor-pointer">
          <img src={Error} className="mx-auto" />
        </span>
      </div>
    );
  }
};

export default ErrorPage;
